import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Appwrap, MotionWrap } from '../../wrapper';

// import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';
import { urlFor, client } from '../../client';

const About = () => {
  const [about, setAbout] = useState([]);

  useEffect(() => {
    const query = '*[_type == "about"]';

    client.fetch(query).then((data) => {
      setAbout(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">My name is<br /><span>Gregg Akamine.</span></h2>
      <h5>I am a software engineer based in Honolulu, HI. As a former music teacher I enjoy the creative and technical process of making an entity from its many parts. I value learning and am always striving to be better whether as a developer or as a person. I am excited to be a part of this industry and to see how technology will continue to help and positively change our world.</h5>
      <br />
      <h5>Some of my hobbies and interests include...</h5>

      <div className="app__profiles">
        {about.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text " style={{ marginTop: 20 }}>{about.title}</h2>
            <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default Appwrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
  );