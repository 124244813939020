import React from 'react';
import { BsLinkedin, BsInstagram, BsGithub } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';


const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
          <a href='https://www.linkedin.com/in/gregg-akamine/' target="_blank"><BsLinkedin /></a>
        </div>
        <div>
          <a href='https://github.com/greggaka' target="_blank"><BsGithub /></a>
        </div>
        <div>
          <a href='mailto:greggakamine@gmail.com'><HiOutlineMail /></a>
        </div>
        <div>
          <a href='https://www.instagram.com/gakamine117/' target="_blank"><BsInstagram /></a>
        </div>
    </div>
  )
}

export default SocialMedia